.cardTitle {
    font-size: 30px;
    font-weight: 300;
    display: block;
    line-height: 32px;
    margin-bottom: 8px
}

.vendorLogoWrapper {
    display: flex;
    justify-content: center;
    padding: 35px 0 30px 0;
  }

.vendorLogoWrapper img {
    height: 100px;
    max-height: 70px;
    max-width: 100%;
  }