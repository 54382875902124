.root {
  background-color: #1473e6;
  box-shadow: 0px 5px 58px rgba(13, 67, 113, 0.278);
  border-radius: 8px;
  color: white;
}

.photo {
  background-color: white;
  border-radius: 50%;
  max-width: 200px;
}

@media (min-width: 1920px) {
  .root {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 8px;
  }
  .mdColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .photo {
    width: 85%;
  }
}

/* @media (min-width: 1920px) {
  .root {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 8px;
  }
  .body {
    width: 100%;
    display: flex;
    flex-grow: 1;
  }
  .body > * {
    width: 33.333%;
  }
  .mdColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 12px 40px;
  }
  .photo {
    width: 85%;
  }
} */

@media (max-width: 1919px) {
  .root {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .body {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
  }
  .mdColumn {
    width: 83%;
    text-align: center;
    padding: 12px 40px;
  }
}

@media (max-width: 1536px) {
  .root {
    width: 100%;
    height: 100%;
    display: block;
    align-items: center;
  }
  .body {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
  }
  .mdColumn {
    width: 83%;
    text-align: center;
    padding: 12px 40px;
  }
}

/* Using Bootstrap breakpoint out of personal preference */
@media (max-width: 1024px) {
  .mdColumn {
    padding: 12px 10px;
  }
}
@media (max-width: 768px) {
  .root {
    width: 100%;
    height: auto;
  }
  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mdColumn {
    width: 83%;
    text-align: center;
    padding: 12px 40px;
  }
}
