.vendorLogoWrapper {
  display: flex;
  justify-content: center;
}

.phVendorLogoWrapper {
  display: flex;
  justify-content: center;
  padding: 0 0 32px 0;
}

.vendorLogoWrapper img {
  height: 100px;
  max-width: 100%;
}
.phVendorLogoWrapper img {
  height: 115px;
}

.centerText {
  text-align: center;
}

.defaultTextColor {
  color: #1473e6;
}

.stepperTitle {
  text-align: center;
  text-transform: capitalize;
}

.finePrint {
  font-size: 11px;
}

.horizontalRule {
  border: #1473e6 solid 1px;
  opacity: 0.23;
  width: 90%;
}

.vehiclePhotoWrapper {
  display: flex;
  justify-content: center;
  padding: 15px 0 10px 0;
}

.vehiclePhotoWrapper img {
  max-height: 200px;
  max-width: 100%;
}

.sslLogo {
  height: auto;
  width: auto;
  max-width: 40%;
  max-height: 75px;
}

.poweredByDCRLogo {
  width: auto;
  height: auto;
  max-width: 250px;
  height: 50px;
}

.poweredByDCRLogoContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.sslLogoContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sslTextContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 20px;
  border-left: 2px solid #e5e5e5;
}

.footerText {
  width: 41.5%;
  margin-bottom: 60px;
}
