.card {
  background-color: #1473e6;
  border-radius: 8px;
  box-shadow: -1px 27px 45px rgba(8, 52, 155, 0.15);
  color: white;
  width: 75%;
  padding: 20px;
  box-sizing: border-box;
}

.bigInputs {
  margin-top: 0;
  margin-bottom: 15px;
}

.flexRow {
  display: flex;
  justify-content: space-around;
}

.flexColumn {
  text-align: center;
}

.labelFont {
  text-transform: uppercase;
}
