.root {
  background-color: white;
  /* box-shadow: 0px 5px 58px rgba(64, 148, 222, 0.141); */
  border-radius: 8px;
  display: inline-block;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.iframeRoot {
  padding: 20px 20px 0 20px;
}
