.card {
  background-color: rgba(20, 115, 230, 0.3);
  color: #1473e6;
  border-radius: 8px;
  /* padding: 20px; */
  display: inline-block;
  box-sizing: border-box;
  width: 240px;
  margin: 6px 10px;
  cursor: pointer;
}

.gbbCard {
  background-color: rgba(20, 115, 230, 0.3);
  color: #1473e6;
  border-radius: 8px;
  /* padding: 20px; */
  display: inline-block;
  box-sizing: border-box;
  /* width: 300px; */
  margin: 6px 10px;
  cursor: pointer;
  width: calc(100% - 8px);
  max-width: 250px;
  min-width: 208px;
}

.greyText {
  color: #707070;
}
