.defaultTextColor {
  color: #1473e6;
}

.modalCard {
  padding: 20px;
  width: 800px;
  background-color: white;
  border-radius: 8px;
  text-align: center;
}

.modalCardGBB {
  padding: 0;
  width: 640px;
  background-color: white;
  border-radius: 8px;
  text-align: center;
}

.headerCardWrapper {
  display: flex;
  justify-content: center;
}

.termBoxWrapper {
  display: flex;
  justify-content: center;
}

.termBox {
  border: 1px solid #707070;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 90%;
}

.termBox > * {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.labelText {
  text-transform: uppercase;
}

.buttonsBoxWrapper {
  display: flex;
  justify-content: center;
}

.buttonsBox {
  display: flex;
  width: 250px;
  justify-content: space-around;
}

.finePrint {
  font-size: 11px;
}

.modalBoxes {
  min-width: 200px;
  max-width: 300px;
}

.detailsSegment {
  height: 100%;
}
