.footer {
  height: 125px;
  background-color: #1473e6;
  display: flex;
  align-items: center;
}

.footerFlex {
  display: flex;
  justify-content: space-between;
  color: white;
}

/* Using Bootstrap breakpoint out of personal preference */
@media (max-width: 768px) {
  .footerFlex {
    flex-direction: column;
  }
}
