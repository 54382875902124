body {
  margin: 0;
  background-color: #e5e5e5;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.h3 {
  font-size: 40px;
  font-weight: 500;
}

.h4 {
  font-size: 34px;
  font-weight: 500;
}

.h5 {
  font-size: 20px;
  font-weight: 500;
}

.pGrey {
  color: #707070;
}

.footerText {
  font-size: 12px;
}
