.formSubheader {
  color: #1473e6;
  margin-bottom: 20px;
}

.vendorLogoWrapper {
  display: flex;
  justify-content: center;
  padding: 35px 0 30px 0;
}
.phVendorLogoWrapper {
  display: flex;
  justify-content: center;
  padding: 35px 0 30px 0;
}

.partnerLogoBg {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: -20px -20px 0px -20px;
}

.highwayLogo {
  max-height: 200px;
  height: 200px;
  margin-right: 20px;
}

.vendorLogoWrapper img {
  height: 150px;
  max-height: 150px;
  max-width: 100%;
}

.phVendorLogoWrapper img {
  height: 150px;
}

.centerText {
  text-align: center;
}

.sendEmailButtonContainer {
  justify-content: flex-end;
}

.sendEmailButton {
  background-color: #3e83e6 !important;
  color: #fff !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  width: 132px;
  height: 40px;
  padding-bottom: 0;
}

.sendEmailButton:disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.horizontalRule {
  border: #1473e6 solid 1px;
  opacity: 0.23;
  width: 80%;
}

.partnerForm {
  padding: 50px 90px 0;
  background-color: white;
}

.partnerFormField {
  margin-bottom: 20px !important;
}

.partnerFormHeaderText {
  margin-top: 0;
  font-size: 19px;
  color: #000;
  text-transform: uppercase;
}

.partnerFormFieldsContainer {
  margin: 30px 0px 20px;
}

.URLParametrItem {
  color: #818181;
}

.URLParametrText {
  color: #000;
}

.personalInfoStyles {
  width: calc(50% - 30px) !important;
  margin-right: 30px;
}

.partnerFormInput {
  padding: 17px 10px !important;
}

.questionField {
  height: 100%;
}

.questionFieldInput {
  height: 100% !important;
  padding: 17px 10px !important;
}

.actionsContainer {
  display: flex;
  justify-content: flex-end;
  padding: 0px;
}

.captchaContainer {
  justify-content: flex-end;
}

.successFieldContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.succesSentContainer {
  padding: 50px 90px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.succesSentContent {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.succesSentContentTextContainer {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  max-width: 370px;
}

.succesSentContentText {
  color: #606060;
  margin-bottom: 20px;
  font-size: 14px;
}

.sslLogo {
  height: auto;
  width: auto;
  max-width: 40%;
  max-height: 75px;
}

.poweredByTrnsactLogo {
  width: auto;
  height: auto;
  max-width: 250px;
  height: 50px;
}

.poweredByTrnsactLogoContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.sslLogoContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sslTextContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 20px;
  border-left: 2px solid #e5e5e5;
}

.footerText {
  width: 41.5%;
  margin-bottom: 60px;
}

.docsImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.checkmarkDCF {
  height: 20px !important;
}

.sendLinkButton {
  background-color: #3e83e6 !important;
  color: #fff !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  width: 150px;
  height: 40px;
  padding-bottom: 0;
}

.sendLinkButton:disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

@media (max-width: 768px) {
  .poweredByDCRLogo {
    max-width: 100%;
    min-height: 0;
    max-height: 50px;
  }
  .sslLogoContainer {
    justify-content: center;
  }
  .poweredByDCRLogoContainer {
    justify-content: center;
  }
  .sslLogo {
    max-width: 100%;
  }
  .footerText {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 600px) {
  .partnerForm {
    padding: 0;
  }
  .captchaStyles {
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100%;
  }
  .sendEmailButton {
    margin-top: 20px !important;
  }
}

@media (max-width: 959px) {
  .personalInfoStyles {
    width: 100% !important;
    margin-right: 0;
  }
  .captchaContainer {
    justify-content: center;
  }
  .sendEmailButtonContainer {
    justify-content: center;
  }
  .sendEmailButton {
    margin-top: 20px !important;
  }
  .captchaStyles {
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100%;
  }
}

@media (min-width: 960px) and (max-width: 1192px) {
  .captchaStyles {
    padding-bottom: 20px;
  }
}
@media (min-width: 1920px) {
  .captchaStyles {
    padding-bottom: 20px;
  }
}
.h3 {
  font-size: 40px;
  font-weight: 500;
}

.h4 {
  font-size: 34px;
  font-weight: 500;
}

.h5 {
  font-size: 20px;
  font-weight: 500;
}

.pGrey {
  color: #707070;
}

.footerText {
  font-size: 12px;
}
