.card {
  background-color: #1473e6;
  border-radius: 8px;
  box-shadow: -1px 27px 45px rgba(8, 52, 155, 0.15);
  color: white;
  display: inline-block;
  box-sizing: border-box;
  width: 265px;
  margin: 0 10px;
  cursor: pointer;
}

.label {
  text-transform: uppercase;
  font-weight: bold;
}
